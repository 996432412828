@keyframes slideSideBarLeft {
  from {
    left: -150px;
  }
  to {
    left: 0;
  }
}
@keyframes slideSideBarRight {
  from {
    left: 0;
  }
  to {
    left: -150px;
  }
}
#tabs-menu {
  position: fixed;
  float: left;
  left: 0;
  top: 65px;
  height: calc(100vh - 65px);
  width: 150px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.06) 2px 0px 2px;
  color: var(--inovyo-color);
  z-index: 200;
}
#tabs-menu.active {
  left: 0;
  animation-name: slideSideBarLeft;
  animation-duration: 0.2s;
}
#tabs-menu.inactive {
  left: -150px;
  animation-name: slideSideBarRight;
  animation-duration: 0.2s;
}
#tabs-menu .logo-menu {
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-top: solid 1px #eee;
}
#tabs-menu .logo-menu img {
  width: 100px;
}
#tabs-menu > .tabs-items {
  height: calc(100vh - 145px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
#tabs-menu > .tabs-items::-webkit-scrollbar {
  width: 6px;
}
#tabs-menu > .tabs-items::-webkit-scrollbar-thumb {
  background-color: var(--inovyo-color);
  border-radius: 6px;
}
#tabs-menu > .tabs-items::-webkit-scrollbar-track {
  background-color: #ddd;
}
#tabs-menu > .tabs-items > .tabs-menu-item {
  width: 100%;
  border-bottom: solid 1px #eee;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s 0s ease-in-out;
  -moz-transition: all 0.3s 0s ease-in-out;
  -o-transition: all 0.3s 0s ease-in-out;
  transition: all 0.3s 0s ease-in-out;
}
#tabs-menu > .tabs-items > .tabs-menu-item.active {
  display: block;
}
#tabs-menu > .tabs-items > .tabs-menu-item.marked {
  background-color: var(--inovyo-color);
  color: white;
}
#tabs-menu > .tabs-items > .tabs-menu-item.marked svg {
  color: white;
}
#tabs-menu > .tabs-items > .tabs-menu-item svg {
  color: var(--inovyo-color);
}
#tabs-menu > .tabs-items > .tabs-menu-item:hover {
  background-color: var(--inovyo-color-light);
  color: white;
}
#tabs-menu > .tabs-items > .tabs-menu-item:hover svg {
  color: white;
}
#tabs-menu > .tabs-items .add-new-page {
  width: 100%;
  text-align: center;
}
#tabs-menu > .tabs-items .add-new-page span {
  display: block;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  background-color: #eee;
}
#tabs-menu > .tabs-items .add-new-page span:hover {
  background-color: #ddd;
}

#modal-tab-menu h6 {
  padding: 5px 15px;
  margin-bottom: 0;
  font-size: 14px;
}
#modal-tab-menu .color {
  padding: 5px 15px;
}
#modal-tab-menu .color input {
  width: 100%;
  border: none;
}
#modal-tab-menu .add-page {
  width: 100%;
  padding: 5px;
  text-align: center;
}
#modal-tab-menu > .modal-tab-menu-items {
  width: 100%;
  margin: 0;
  overflow: hidden;
  padding: 5px 0;
}
#modal-tab-menu > .modal-tab-menu-items:hover {
  background-color: #eee;
}
#modal-tab-menu > .modal-tab-menu-items .icon,
#modal-tab-menu > .modal-tab-menu-items .name,
#modal-tab-menu > .modal-tab-menu-items .delete {
  position: relative;
  float: left;
  left: 0;
  top: 0;
}
#modal-tab-menu > .modal-tab-menu-items .icon,
#modal-tab-menu > .modal-tab-menu-items .delete {
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
}
#modal-tab-menu > .modal-tab-menu-items .delete {
  color: red;
  line-height: 15px;
}
#modal-tab-menu > .modal-tab-menu-items .delete svg {
  font-size: 20px;
}
#modal-tab-menu > .modal-tab-menu-items .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
#modal-tab-menu > .modal-tab-menu-items .name {
  padding: 5px;
  width: calc(100% - 80px);
  height: 40px;
}

#modal-tab-menu-icons {
  overflow: hidden;
}
#modal-tab-menu-icons > .item {
  width: 40px;
  height: 40px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
#modal-tab-menu-icons > .item:hover {
  background-color: #bbb;
}
