#csat-combo-inovyo .details-table {
  width: 100%;
}
#csat-combo-inovyo .details-table td {
  width: 30%;
  text-align: center;
}
#csat-combo-inovyo .box-horizontal-csat {
  width: 100%;
}
#csat-combo-inovyo .box-horizontal-csat .content {
  width: 100%;
  margin-top: 20px;
}
#csat-combo-inovyo .box-horizontal-csat .content .bar1,
#csat-combo-inovyo .box-horizontal-csat .content .bar2,
#csat-combo-inovyo .box-horizontal-csat .content .bar3,
#csat-combo-inovyo .box-horizontal-csat .content .bar4,
#csat-combo-inovyo .box-horizontal-csat .content .bar5,
#csat-combo-inovyo .box-horizontal-csat .content .bar6,
#csat-combo-inovyo .box-horizontal-csat .content .bar7,
#csat-combo-inovyo .box-horizontal-csat .content .bar8,
#csat-combo-inovyo .box-horizontal-csat .content .bar9,
#csat-combo-inovyo .box-horizontal-csat .content .bar10,
#csat-combo-inovyo .box-horizontal-csat .content .bar0-6 {
  height: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px;
}
#csat-combo-inovyo .box-horizontal-csat .content .bar5,
#csat-combo-inovyo .box-horizontal-csat .content .bar10 {
  background-color: #4ec04e;
}
#csat-combo-inovyo .box-horizontal-csat .content .bar4,
#csat-combo-inovyo .box-horizontal-csat .content .bar9 {
  background-color: #acc75d;
}
#csat-combo-inovyo .box-horizontal-csat .content .bar3,
#csat-combo-inovyo .box-horizontal-csat .content .bar8 {
  background-color: #ffc84a;
}
#csat-combo-inovyo .box-horizontal-csat .content .bar2,
#csat-combo-inovyo .box-horizontal-csat .content .bar7 {
  background-color: #fa8a48;
}
#csat-combo-inovyo .box-horizontal-csat .content .bar1,
#csat-combo-inovyo .box-horizontal-csat .content .bar0-6 {
  background-color: #eb4841;
}
#csat-combo-inovyo .box-horizontal-csat .legend {
  text-align: center;
  padding: 10px;
}
#csat-combo-inovyo .box-horizontal-csat .legend .legend,
#csat-combo-inovyo .box-horizontal-csat .legend .legend1,
#csat-combo-inovyo .box-horizontal-csat .legend .legend2,
#csat-combo-inovyo .box-horizontal-csat .legend .legend3,
#csat-combo-inovyo .box-horizontal-csat .legend .legend4,
#csat-combo-inovyo .box-horizontal-csat .legend .legend5,
#csat-combo-inovyo .box-horizontal-csat .legend .legend6,
#csat-combo-inovyo .box-horizontal-csat .legend .legend7,
#csat-combo-inovyo .box-horizontal-csat .legend .legend8,
#csat-combo-inovyo .box-horizontal-csat .legend .legend9,
#csat-combo-inovyo .box-horizontal-csat .legend .legend10,
#csat-combo-inovyo .box-horizontal-csat .legend .legend0-6 {
  height: 20px;
  width: 30px;
  display: inline-block;
  line-height: 20px;
  text-align: center;
  color: white;
  margin: auto 5px;
}
#csat-combo-inovyo .box-horizontal-csat .legend .legend5, #csat-combo-inovyo .box-horizontal-csat .legend .legend10 {
  background-color: #4ec04e;
}
#csat-combo-inovyo .box-horizontal-csat .legend .legend4, #csat-combo-inovyo .box-horizontal-csat .legend .legend9 {
  background-color: #acc75d;
}
#csat-combo-inovyo .box-horizontal-csat .legend .legend3, #csat-combo-inovyo .box-horizontal-csat .legend .legend8 {
  background-color: #ffc84a;
}
#csat-combo-inovyo .box-horizontal-csat .legend .legend2, #csat-combo-inovyo .box-horizontal-csat .legend .legend7 {
  background-color: #fa8a48;
}
#csat-combo-inovyo .box-horizontal-csat .legend .legend1, #csat-combo-inovyo .box-horizontal-csat .legend .legend0-6 {
  background-color: #eb4841;
}
