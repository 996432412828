#card-color {
  height: 120px;
  background: rgb(255, 255, 255);
  margin-bottom: 10px;
  /** ICONES **/
  /*** Airplane ***/
  /*** Buildings ***/
  /*** charts ***/
}
#card-color .edit-colorfull-card {
  position: absolute;
  float: right;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 5px;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  z-index: 2;
}
#card-color .edit-colorfull-card svg {
  color: white;
}
#card-color span {
  display: block;
  width: 100%;
  padding: 10px 20px 0 20px;
  font-size: 20px;
}
#card-color h1 {
  display: block;
  width: 100%;
  padding: 0 20px;
  font-size: 50px;
}
#card-color .icon {
  position: absolute;
  float: right;
  right: 0;
  top: 0;
  height: 100%;
  width: 150px;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}
#card-color .icon-airplane-0001 {
  background-image: url("../../../images/icons/airplane/airplane-0001.svg");
}
#card-color .icon-airplane-0002 {
  background-image: url("../../../images/icons/airplane/airplane-0002.svg");
}
#card-color .icon-airplane-0003 {
  background-image: url("../../../images/icons/airplane/airplane-0003.svg");
}
#card-color .icon-airplane-0004 {
  background-image: url("../../../images/icons/airplane/airplane-0004.svg");
}
#card-color .icon-airplane-0005 {
  background-image: url("../../../images/icons/airplane/airplane-0005.svg");
}
#card-color .icon-build-0001 {
  background-image: url("../../../images/icons/builds/build-0001.svg");
  background-size: 60%;
}
#card-color .icon-build-0002 {
  background-image: url("../../../images/icons/builds/build-0002.svg");
  background-size: 60%;
}
#card-color .icon-build-0003 {
  background-image: url("../../../images/icons/builds/build-0003.svg");
  background-size: 60%;
}
#card-color .icon-build-0004 {
  background-image: url("../../../images/icons/builds/build-0004.svg");
  background-size: 60%;
}
#card-color .icon-chart-0001 {
  background-image: url("../../../images/icons/charts/chart-0001.svg");
  background-size: 60%;
}
#card-color .icon-chart-0002 {
  background-image: url("../../../images/icons/charts/chart-0002.svg");
  background-size: 80%;
}
#card-color .icon-chart-0003 {
  background-image: url("../../../images/icons/charts/chart-0003.svg");
}
#card-color .icon-chart-0004 {
  background-image: url("../../../images/icons/charts/chart-0004.svg");
}
#card-color .icon-chart-0005 {
  background-image: url("../../../images/icons/charts/chart-0005.svg");
}
#card-color .icon-chart-0006 {
  background-image: url("../../../images/icons/charts/chart-0006.svg");
  background-size: 60%;
}
#card-color .icon-chart-0007 {
  background-image: url("../../../images/icons/charts/chart-0007.svg");
}
#card-color .icon-chart-0008 {
  background-image: url("../../../images/icons/charts/chart-0008.svg");
  background-size: 60%;
}

#modal-card-colorfull h6 {
  padding: 5px 15px;
  margin-bottom: 0;
  font-size: 14px;
}
#modal-card-colorfull .color {
  padding: 5px 15px;
}
#modal-card-colorfull .color input {
  width: 100%;
  border: none;
}
#modal-card-colorfull .input {
  width: 100%;
  padding: 5px 15px;
}
