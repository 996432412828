#home-page {
  padding: 10px;
}
#home-page .item {
  border: solid 1px #ddd;
  padding: 10px;
  border-radius: 5px;
  min-height: 100px;
  display: block;
  color: #000;
}
#home-page .item .icon {
  position: relative;
  float: left;
  left: 0;
  right: 0;
  height: 100px;
  width: 100px;
}
#home-page .item .icon img {
  width: 80px;
  height: 80px;
}
#home-page .item .description {
  position: relative;
  float: left;
  left: 0;
  right: 0;
  overflow-wrap: break-word;
  width: calc(100% - 100px);
}
#home-page .item .description span {
  color: #777;
}
