.i-kpi-card {
  margin: 10px;
  background-color: #7441b1;
  color: white;
  border-radius: 8px;
  overflow: hidden;
}
.i-kpi-card em {
  display: block;
  float: left;
  margin: 20px;
  font-style: normal;
  font-size: 18px;
}
.i-kpi-card ul {
  float: right;
  list-style: none;
}
.i-kpi-card ul > li {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  flex-direction: column;
}
.i-kpi-card ul > li:nth-child(1) {
  background-color: #5b338b;
}
.i-kpi-card ul > li:nth-child(2) {
  background-color: #392057;
}
.i-kpi-card ul > li:nth-child(3) {
  background-color: #170d24;
}
.i-kpi-card ul > li > strong,
.i-kpi-card ul > li > span {
  display: block;
}
.i-kpi-card ul > li > span {
  font-size: 20px;
}
