@keyframes slideContentLeft {
  from {
    width: calc(100% - 150px);
    margin-left: 150px;
  }
  to {
    width: 100%;
    margin-left: 0px;
  }
}
@keyframes slideContentRight {
  from {
    width: 100%;
    margin-left: 0px;
  }
  to {
    width: calc(100% - 150px);
    margin-left: 150px;
  }
}
#dashboard-page {
  width: 100%;
  padding: 0 !important;
}
#dashboard-page.active {
  width: calc(100% - 150px);
  margin-left: 150px;
  animation-name: slideContentRight;
  animation-duration: 0.2s;
}
#dashboard-page.inactive {
  width: 100%;
  margin-left: 0px;
  animation-name: slideContentLeft;
  animation-duration: 0.2s;
}
#dashboard-page .content-dashboard-filter {
  width: 100%;
  height: 40px;
  background-color: #ddd;
  box-shadow: inset rgba(0, 0, 0, 0.2) 0px 0px 5px;
}
#dashboard-page .content-dashboard {
  width: 100%;
  padding: 10px 0;
}
#dashboard-page .content-dashboard .content-dashboard-item.active {
  display: block;
}
#dashboard-page .content-dashboard .content-dashboard-item .antd-row {
  margin-bottom: 10px;
}
#dashboard-page .add-new-row {
  width: 100%;
  text-align: center;
}

@media all and (max-width: 768px) {
  @keyframes slideContentLeft {
    from {
      width: 100%;
      margin-left: 0px;
    }
    to {
      width: 100%;
      margin-left: 0px;
    }
  }
  @keyframes slideContentRight {
    from {
      width: 100%;
      margin-left: 0px;
    }
    to {
      width: 100%;
      margin-left: 0px;
    }
  }
  #dashboard-page.active {
    width: 100%;
    margin-left: 0px;
  }
}
