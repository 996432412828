#card-default {
  margin-bottom: 10px;
}
#card-default .card-header .card-box-buttons {
  position: absolute;
  float: right;
  right: 0;
  top: 0;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#card-default .card-header .card-box-buttons .card-header-btn {
  width: 36px;
  height: 32px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
#card-default .card-header .card-box-buttons .card-header-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
#card-default .card-body {
  min-height: 250px;
}
