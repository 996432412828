:root {
  --inovyo-color: #9a2376;
  --inovyo-color-light: #a74489;
  --inovyo-color-dark: #811360;
}
:root --flex-center {
  justify-content: center;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f2f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.loading {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading span {
  font-size: 40px;
  font-weight: bold;
}

.loading-full {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 10000;
  font-size: 40px;
}
.loading-full span {
  font-size: 40px;
  font-weight: bold;
}

.logo {
  width: 178px;
  height: 44px;
  background-image: url("../images/logo-inovyo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 10px 32px 10px 0;
  float: left;
}

.logo-cliente {
  width: 178px;
  height: 44px;
  margin: 10px 32px 10px 0;
  float: left;
}

/* AJUSTES NO FRAMEWORK ANTD */
.ant-message .anticon {
  top: -3px;
}

.ant-layout-header,
.ant-layout-sider {
  background: #fff;
}

.site-layout .site-layout-background {
  background: #fff;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .ant-layout-header {
    padding: 0 22px;
  }
}
