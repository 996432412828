.loading-data,
.loading-error {
  width: 100%;
  text-align: center;
  padding: 200px;
}
.loading-data span,
.loading-error span {
  font-size: 20px;
  font-weight: bold;
}

.loading-error span {
  color: red;
}

#link-excel {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: white;
  text-align: center;
  border-radius: 5px;
  background-color: #236b23;
}

#link-excel.process {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: white;
  text-align: center;
  border-radius: 5px;
  background-color: #23406b;
}

#link-excel.error {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: white;
  text-align: center;
  border-radius: 5px;
  background-color: #db2d2d;
}

#dahboard-light {
  width: calc(100% - 120px);
  margin-left: 120px;
  padding-top: 50px;
}
#dahboard-light .filter-box {
  width: 100%;
  background-color: #ddd;
  clear: both;
  overflow: hidden;
  position: fixed;
  top: 65px;
  z-index: 20;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.3) 3px 1px 5px;
}
#dahboard-light .filter-box button {
  float: right;
  top: 10px;
  right: 5px;
}
#dahboard-light .filter-box button svg {
  padding-top: 4px;
}
#dahboard-light .filter-box .item {
  width: 20%;
  height: 40px;
  position: relative;
  float: left;
  left: 0;
  top: 0;
  margin: 5px;
  border-radius: 10px;
  border: solid 1px #ccc;
  background-color: #eee;
}
#dahboard-light .filter-box .item > span {
  display: block;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 0 0 10px;
}
#dahboard-light .filter-box .item .filtros {
  padding: 0 0 0 10px;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
#dahboard-light #nps-dist .wrap1 {
  position: relative;
  overflow: hidden;
}
#dahboard-light #nps-dist .wrap1 > .piece1,
#dahboard-light #nps-dist .wrap1 > .piece2,
#dahboard-light #nps-dist .wrap1 > .piece3 {
  width: 33.333333333%;
  position: relative;
  float: left;
  left: 0px;
  top: 0px;
  height: 90px;
  padding: 10px;
}
#dahboard-light #nps-dist .wrap1 > .piece1 > span,
#dahboard-light #nps-dist .wrap1 > .piece2 > span,
#dahboard-light #nps-dist .wrap1 > .piece3 > span {
  display: block;
  font-size: 22px;
  font-weight: bold;
}
#dahboard-light #nps-dist .wrap1 > .piece1 {
  border-left: solid 5px #eb4841;
}
#dahboard-light #nps-dist .wrap1 > .piece2 {
  border-left: solid 5px #ffc84a;
}
#dahboard-light #nps-dist .wrap1 > .piece3 {
  border-left: solid 5px #4ec04e;
}
#dahboard-light #nps-dist .wrap2 {
  position: relative;
  margin-top: 10px;
}
#dahboard-light #nps-dist .wrap2 > .color1,
#dahboard-light #nps-dist .wrap2 > .color2,
#dahboard-light #nps-dist .wrap2 > .color3 {
  position: relative;
  float: left;
  left: 0px;
  top: 0px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  font-size: 20px;
  color: white;
  font-weight: bold;
}
#dahboard-light #nps-dist .wrap2 > .color1 {
  background-color: #eb4841;
}
#dahboard-light #nps-dist .wrap2 > .color2 {
  background-color: #ffc84a;
}
#dahboard-light #nps-dist .wrap2 > .color3 {
  background-color: #4ec04e;
}

/* @media all and (max-width: 1200px) {} */
@media all and (max-width: 960px) {
  #dahboard-light {
    width: 100%;
    margin-left: 0px;
  }
}
@media all and (max-width: 640px) {
  #dahboard-light {
    width: 100%;
    margin-left: 0px;
  }
}
@media all and (max-width: 480px) {
  #dahboard-light {
    width: 100%;
    margin-left: 0px;
  }
}
