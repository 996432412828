#i-menu {
  width: 120px;
  height: calc(100vh - 64px);
  background-color: white;
  position: fixed;
  top: 64px;
  left: 0;
  float: left;
}
#i-menu .i-menu-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 154px);
}
#i-menu .i-menu-content::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
#i-menu .i-menu-content a {
  display: flex;
  min-height: 70px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: solid 0.5px #ddd;
  padding: 10px 5px;
}
#i-menu .i-menu-content a span {
  font-size: 16px;
  text-align: center;
}
#i-menu .i-menu-content a svg {
  font-size: 20px;
  margin-bottom: 5px;
}
#i-menu .i-menu-content a:hover {
  background-color: #f8f9fa;
}
#i-menu .i-menu-content.pw::-webkit-scrollbar {
  background-color: #eccde3;
}
#i-menu .i-menu-content.pw::-webkit-scrollbar-thumb {
  background: #002a4e;
}
#i-menu .i-menu-content.pw a {
  color: #002a4e;
}
#i-menu .i-menu-content.py::-webkit-scrollbar {
  background-color: #eccde3;
}
#i-menu .i-menu-content.py::-webkit-scrollbar-thumb {
  background: #5b338b;
}
#i-menu .i-menu-content.py a {
  color: #5b338b;
}
#i-menu .i-menu-logo img {
  position: fixed;
  bottom: 10px;
  left: 13px;
  float: left;
  height: 60px;
}
