.loading-ui {
  width: 100%;
  height: 100vh;
  background-color: hsla(0, 0%, 100%, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}
