#header-app .navbar-toggler {
  display: none;
}
#header-app svg.logo-exit {
  position: absolute;
  float: right;
  top: 17px;
  right: 25px;
  width: 30px;
  font-size: 30px;
  cursor: pointer;
}
#header-app .menu-main-inovyo {
  height: 100%;
}
#header-app .menu-main-inovyo a {
  display: inline-block;
  height: 100%;
  padding: 0 10px;
}
#header-app .menu-main-inovyo a:hover {
  border-bottom: solid 3px var(--inovyo-color);
  background-color: #f5f5f5;
}
@media only screen and (max-width: 768px) {
  #header-app .navbar-toggler {
    display: block;
    float: left;
    position: relative;
    top: 12px;
    padding: 8px;
    left: -5px;
  }
  #header-app .navbar-toggler svg {
    font-size: 20px;
  }
}

#header-dashboard {
  width: 100%;
  height: 65px;
  background-color: white;
  z-index: 1000;
  position: fixed;
  float: left;
  left: 0;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 2px;
}
#header-dashboard .header-layout {
  display: flex;
  flex-direction: row;
  height: 100%;
}
#header-dashboard .header-layout .menu-details {
  position: absolute;
  float: right;
  right: 0;
  height: 100%;
}
#header-dashboard .header-layout .menu-details .btn-settings {
  height: 100%;
  width: 65px;
  position: relative;
  float: right;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#header-dashboard .header-layout .menu-details .btn-settings svg {
  font-size: 20px;
  color: var(--inovyo-color-dark);
  cursor: pointer;
}
#header-dashboard .header-layout .menu-details .btn-settings svg:hover {
  font-size: 22px;
}
#header-dashboard .header-layout .menu-details .desc-dash {
  height: 100%;
  position: relative;
  float: right;
  right: 0;
  padding: 8px 15px 0 10px;
  text-align: right;
}
#header-dashboard .header-layout .menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 100%;
}
#header-dashboard .header-layout .menu svg {
  font-size: 30px;
  cursor: pointer;
}

#header-dashboard-light {
  width: 100%;
  height: 65px;
  background-color: white;
  z-index: 1000;
  position: fixed;
  float: left;
  left: 0;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 2px;
}
#header-dashboard-light .header-layout {
  height: 100%;
}
#header-dashboard-light .header-layout img.logo-light {
  position: relative;
  float: left;
  top: 0;
  left: 0;
  width: 150px;
  margin: 10px 0 0 10px;
}
#header-dashboard-light .header-layout .dash-name {
  position: absolute;
  float: right;
  top: 20px;
  right: 85px;
  font-size: 15px;
  cursor: pointer;
}
#header-dashboard-light .header-layout svg.logo-exit {
  position: absolute;
  float: right;
  top: 17px;
  right: 25px;
  width: 30px;
  font-size: 30px;
  cursor: pointer;
}
#header-dashboard-light .header-layout .menu-details {
  position: absolute;
  float: right;
  right: 0;
  height: 100%;
}
#header-dashboard-light .header-layout .menu-details .btn-settings {
  height: 100%;
  width: 65px;
  position: relative;
  float: right;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#header-dashboard-light .header-layout .menu-details .btn-settings svg {
  font-size: 20px;
  color: var(--inovyo-color-dark);
  cursor: pointer;
}
#header-dashboard-light .header-layout .menu-details .btn-settings svg:hover {
  font-size: 22px;
}
#header-dashboard-light .header-layout .menu-details .desc-dash {
  height: 100%;
  position: relative;
  float: right;
  right: 0;
  padding: 8px 15px 0 10px;
  text-align: right;
}
#header-dashboard-light .header-layout .menu {
  position: relative;
  float: left;
  top: 0;
  left: 0;
  width: 65px;
  height: 100%;
  text-align: center;
  line-height: 85px;
}
#header-dashboard-light .header-layout .menu svg {
  font-size: 30px;
  cursor: pointer;
}
