#users-page {
  padding: 10px;
  position: relative;
  overflow: hidden;
}
#users-page .float-button {
  float: right;
  position: absolute;
  top: 15px;
  right: 25px;
}
