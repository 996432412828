#i-card {
  margin: 10px;
}
#i-card .title {
  padding: 20px 0 0 20px;
  font-weight: bold;
  font-size: 16px;
}
#i-card .value {
  padding: 5px 20px;
  font-size: 50px;
  font-weight: bold;
}
#i-card .icon {
  position: absolute;
  float: right;
  right: 20px;
  top: 30px;
  font-size: 50px;
  opacity: 0.3;
}
