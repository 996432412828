#csat-lines-inovyo .line-data {
  width: 100%;
  border-bottom: solid 1px #ddd;
}
#csat-lines-inovyo .line-data:last-child {
  border-bottom: none;
}
#csat-lines-inovyo .line-data .titles,
#csat-lines-inovyo .line-data .avg,
#csat-lines-inovyo .line-data .total {
  height: 20px;
  display: inline-block;
  line-height: 20px;
}
#csat-lines-inovyo .line-data .titles {
  width: 40%;
  font-weight: bold;
}
#csat-lines-inovyo .line-data .avg {
  width: 30%;
  text-align: center;
}
#csat-lines-inovyo .line-data .total {
  width: 30%;
  text-align: center;
}
#csat-lines-inovyo .box-horizontal-csat {
  width: 100%;
}
#csat-lines-inovyo .box-horizontal-csat .content {
  width: 100%;
}
#csat-lines-inovyo .box-horizontal-csat .content .bar-1,
#csat-lines-inovyo .box-horizontal-csat .content .bar-2,
#csat-lines-inovyo .box-horizontal-csat .content .bar-3,
#csat-lines-inovyo .box-horizontal-csat .content .bar-4,
#csat-lines-inovyo .box-horizontal-csat .content .bar-5,
#csat-lines-inovyo .box-horizontal-csat .content .bar-6,
#csat-lines-inovyo .box-horizontal-csat .content .bar-7,
#csat-lines-inovyo .box-horizontal-csat .content .bar-8,
#csat-lines-inovyo .box-horizontal-csat .content .bar-9,
#csat-lines-inovyo .box-horizontal-csat .content .bar-10,
#csat-lines-inovyo .box-horizontal-csat .content .bar-0-6,
#csat-lines-inovyo .box-horizontal-csat .content .bar-na {
  height: 25px;
  display: inline-block;
  line-height: 25px;
  text-align: center;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px;
}
#csat-lines-inovyo .box-horizontal-csat .content .bar-5, #csat-lines-inovyo .box-horizontal-csat .content .bar-10 {
  background-color: #4ec04e;
}
#csat-lines-inovyo .box-horizontal-csat .content .bar-4, #csat-lines-inovyo .box-horizontal-csat .content .bar-9 {
  background-color: #acc75d;
}
#csat-lines-inovyo .box-horizontal-csat .content .bar-3, #csat-lines-inovyo .box-horizontal-csat .content .bar-8 {
  background-color: #ffc84a;
}
#csat-lines-inovyo .box-horizontal-csat .content .bar-2, #csat-lines-inovyo .box-horizontal-csat .content .bar-7 {
  background-color: #fa8a48;
}
#csat-lines-inovyo .box-horizontal-csat .content .bar-1, #csat-lines-inovyo .box-horizontal-csat .content .bar-0-6 {
  background-color: #eb4841;
}
#csat-lines-inovyo .box-horizontal-csat .content .bar-na {
  background-color: #ccc;
}
